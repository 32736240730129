// general testimonies
import avatarGeneralPaksi from '~/assets/testimony/general/paksi.png'
import avatarGeneralRyan from '~/assets/testimony/general/ryan.png'
import avatarGeneralFaizal from '~/assets/testimony/general/faizal.png'
import avatarGeneralMandiri from '~/assets/testimony/general/mandiri.png'
import avatarGeneralArdi from '~/assets/testimony/general/ardi.png'
import avatarGeneralRizki from '~/assets/testimony/general/rizki.png'
import avatarGeneralIhsan from '~/assets/testimony/general/ihsan.png'
import avatarGeneralKemkominfo from '~/assets/testimony/general/kemkominfo.png'
import avatarGeneralRahma from '~/assets/testimony/general/rahma.png'
import avatarGeneralParagon from '~/assets/testimony/general/paragon.png'
import avatarGeneralBoma from '~/assets/testimony/general/boma.png'

// impact testimonies
import avatarImpactPaksi from '~/assets/testimony/impact/paksi.png'
import avatarImpactArkhan from '~/assets/testimony/impact/arkhan.png'
import avatarImpactDave from '~/assets/testimony/impact/dave.png'
import avatarImpactLatifah from '~/assets/testimony/impact/latifah.png'
import avatarImpactEvira from '~/assets/testimony/impact/evira.png'
import avatarImpactDzul from '~/assets/testimony/impact/dzul.png'
import avatarImpactRajib from '~/assets/testimony/impact/rajib.png'
import avatarImpactYusril from '~/assets/testimony/impact/yusril.png'
import avatarImpactHamdiki from '~/assets/testimony/impact/hamdiki.png'
import avatarImpactBrian from '~/assets/testimony/impact/brian.png'
import avatarImpactDedy from '~/assets/testimony/impact/dedy.png'
import avatarImpactArya from '~/assets/testimony/impact/arya.png'
import avatarImpactFauzan from '~/assets/testimony/impact/fauzan.png'
import avatarImpactRizqullah from '~/assets/testimony/impact/rizqullah.png'
import avatarImpactRizki from '~/assets/testimony/impact/rizki.png'

// product specific testimonies: bootcamp
import posterMemberPaksi from '~/assets/testimony/member/bootcamp/1.jpg'
import posterMemberAmanda from '~/assets/testimony/member/bootcamp/2.jpg'
import posterMemberDavid from '~/assets/testimony/member/bootcamp/3.jpg'
import posterMemberAndi from '~/assets/testimony/member/bootcamp/4.jpg'
import posterMemberAhmad from '~/assets/testimony/member/bootcamp/5.jpg'
import posterMemberRivia from '~/assets/testimony/member/bootcamp/6.jpg'
import posterMemberZudi from '~/assets/testimony/member/bootcamp/7.jpg'
import posterMemberIsmayati from '~/assets/testimony/member/bootcamp/8.jpg'
import posterMemberArkhan from '~/assets/testimony/member/bootcamp/9.jpg'
import posterMemberKhai from '~/assets/testimony/member/bootcamp/10.jpg'
import posterMemberBerry from '~/assets/testimony/member/bootcamp/11.jpg'
import posterMemberAsyafa from '~/assets/testimony/member/bootcamp/12.jpg'
import posterMemberHariyono from '~/assets/testimony/member/bootcamp/13.jpg'
import posterMemberSyfa from '~/assets/testimony/member/bootcamp/14.jpg'
import posterMemberRifqi from '~/assets/testimony/member/bootcamp/15.jpg'
import posterMemberAlinda from '~/assets/testimony/member/bootcamp/16.jpg'
import posterMemberFaizal from '~/assets/testimony/member/bootcamp/17.jpg'
import posterMemberEvira from '~/assets/testimony/member/bootcamp/18.jpg'
import posterMemberZainurrahman from '~/assets/testimony/member/bootcamp/19.jpg'
import posterMemberSyahrina from '~/assets/testimony/member/bootcamp/20.jpg'
import posterMemberNizam from '~/assets/testimony/member/bootcamp/21.jpg'
import posterMemberSoraya from '~/assets/testimony/member/bootcamp/22.jpg'
import posterMemberAditya from '~/assets/testimony/member/bootcamp/23.jpg'
import posterMemberFathia from '~/assets/testimony/member/bootcamp/24.jpg'
import psoterMemberStevano from '~/assets/testimony/member/bootcamp/25.jpg'
import posterMemberKaamil from '~/assets/testimony/member/bootcamp/26.jpg'

// product specific testimonies: career mentoring
import posterMemberDedy from '~/assets/testimony/member/career-mentoring/1.png'
import posterMemberFauzan from '~/assets/testimony/member/career-mentoring/2.png'
import posterMemberHafidha from '~/assets/testimony/member/career-mentoring/3.png'
import posterMemberHana from '~/assets/testimony/member/career-mentoring/4.png'
import posterMemberRajib from '~/assets/testimony/member/career-mentoring/5.png'
import posterMemberYusril from '~/assets/testimony/member/career-mentoring/6.png'

// product specific testimonies: e-learning
import posterMemberTaufik from '~/assets/testimony/member/e-learning/1.jpg'
import posterMemberLatifah from '~/assets/testimony/member/e-learning/2.jpg'
import posterMemberRizqullah from '~/assets/testimony/member/e-learning/3.jpg'
import posterMemberSiti from '~/assets/testimony/member/e-learning/4.jpg'
import posterMemberBram from '~/assets/testimony/member/e-learning/5.jpg'
import posterMemberRindi from '~/assets/testimony/member/e-learning/6.jpg'
import posterMemberAgustinus from '~/assets/testimony/member/e-learning/7.jpg'
import posterMemberRizkiAP from '~/assets/testimony/member/e-learning/8.jpg'
import posterMemberLuthfiani from '~/assets/testimony/member/e-learning/9.jpg'
import posterMemberSindy from '~/assets/testimony/member/e-learning/10.jpg'

// product specific testimonies: review cv
import posterMemberOri from '~/assets/testimony/member/review-cv/1.jpg'
import posterMemberIrgi from '~/assets/testimony/member/review-cv/2.jpg'
import posterMemberNovela from '~/assets/testimony/member/review-cv/3.jpg'
import posterMemberRizkiMaulana from '~/assets/testimony/member/review-cv/4.jpg'
import posterMemberAnggito from '~/assets/testimony/member/review-cv/5.jpg'
import posterMemberHadi from '~/assets/testimony/member/review-cv/6.jpg'
import posterMemberKelvin from '~/assets/testimony/member/review-cv/7.jpg'

// ----------------------------------------------------------------------

export const GENERAL_TESTIMONY = Object.freeze([
  {
    type: 'user',
    avatar: avatarGeneralPaksi,
    name: 'Paksi Cahyo Baskoro',
    description: 'Full time copywriter Bank DBS',
    testimony:
      'Aku dapat banyak banget ilmu & praktik yang jumpstart karier. Dari hukum, ke copywriter. Tapi karena ikut Bootcamp MySkill, aku menjadi Copywriter di DBS Bank.'
  },
  {
    type: 'user',
    avatar: avatarGeneralRyan,
    name: 'Ryan Dwana',
    description: 'Business Director at Initiative - Global Media Agency',
    testimony:
      'Materi ini saya rancang dari 10 tahun lebih berkecimpung di multinational marketing agency dan tech companies. Terstruktur dan sesuai standar industri terkini. Yuk mulai menyelami materinya!'
  },
  {
    type: 'user',
    avatar: avatarGeneralFaizal,
    name: 'Faizal Alwan',
    description: 'UIUX Design at PT. Esensi Solusi Buana (ESB)',
    testimony:
      'Jujur, ini pertama ikut Bootcamp UI/UX. Saya langsung tertarik dengan Bootcamp MySkill. Setelah selesai, saya dapat Fultime Job as a UI/UX Designer di ESB. Thank you MySkill!'
  },
  {
    type: 'corporate',
    name: 'Bank Mandiri',
    avatar: avatarGeneralMandiri,
    testimony:
      'Banyak insight & mudah dipahami. Penjelasan materi dilakukan secara interaktif menggunakan kasus yang relate dengan pekerjaan.',
    description: 'Corporate Client'
  },
  {
    type: 'user',
    avatar: avatarGeneralArdi,
    name: 'Moch. Ardi',
    description: 'Business Analyst Manager at Carvil',
    testimony:
      'Materi ini dibuat berdasarkan case yang sering dihadapi di dunia pekerjaan yang sudah saya geluti bertahun-tahun. Sehingga potensinya besar banget buat kamu semua untuk faham secara mendalam. Yuk ikutin satu per satu!'
  },

  {
    type: 'user',
    avatar: avatarGeneralRizki,
    name: 'Rizki Utami',
    description: 'Alumni IELTS Bootcamp',
    testimony:
      'The class is super intensive and interactive, kurikulumnya rapi, tutornya & bisa menjangkau semua. Setelah mengikuti Bootcamp IELTS,  saya mendapat score 7 overall.'
  },
  {
    type: 'user',
    avatar: avatarGeneralIhsan,
    name: 'Ihsan Nur',
    description: 'Fulbright Scholarship Awardee at Ohio University, USA',
    testimony:
      'Materi ini saya rancang berdasarkan pengalaman mengajar English Test Preparation selama 6 tahun. Pelajaran yang disampaikan semoga bisa meningkatkan pemahamanmu dan menjadi guidelines!'
  },
  {
    type: 'corporate',
    name: 'Kemkominfo',
    avatar: avatarGeneralKemkominfo,
    testimony:
      'Materi berbobot dijelaskan dengan ringan. Sangat jelas, insightful dan disampaikan dengan sangat bagus.',
    description: 'Corporate Client'
  },

  {
    type: 'user',
    avatar: null,
    name: 'Ori Meviani',
    description: 'Alumni Review MySkill',
    testimony:
      'CV review MySkill sukses membantu saya mendapatkan pekerjaan di Kemenkes bagian digital transformation. Terima kasih banyak MySkill!'
  },
  {
    type: 'user',
    avatar: avatarGeneralRahma,
    name: 'Rahma Yustika',
    description: 'Head of People Operations at Pinhome',
    testimony:
      'Sangat senang bisa berkontribusi. Saya banyak mengaitkannya dengan pengalaman nyata yang telah saya jalankan selama bertahun-tahun di bidang HR. Semoga bermanfaat bagi teman teman semua!'
  },
  {
    type: 'user',
    avatar: null,
    name: 'Muhammad Rizki Maulana',
    description: 'Alumni Review MySkill',
    testimony:
      'CV review MySkill bagus banget! HRD sangat responsif dan detail. Saya bisa memperbaiki sekaligus improve cv saya. Akhirnya bisa lolos intern di salah satu Multinational FMCG, walau saya bukan dari kampus ternama. Terima kasih MySkill!'
  },
  {
    type: 'corporate',
    name: 'Paragon',
    avatar: avatarGeneralParagon,
    testimony:
      'Penyampaiannya jelas, highlight yang penting disampaikan berulang kali. Sangat baik penjelasannya dengan contoh real, jadi bisa dibayangkan secara langsung.',
    description: 'Corporate Client'
  },
  {
    type: 'user',
    avatar: avatarGeneralBoma,
    name: 'Boma Septiago',
    description: 'Principal Talent Acquisition at a Decacorn Startup',
    testimony:
      'Materi ini dirancang dari pengalaman saya sebagai HR selama sekitar 9 tahun dalam menyeleksi ribuan kandidat dan membantu berkembangnya talenta di perusahaan. Semoga bisa membantu mencapai karir impianmu!'
  }
])

export const IMPACT_TESTIMONY = Object.freeze([
  {
    name: 'Paksi Cahyo Baskoro',
    position: 'Copywriter',
    company: 'DBS Bank Indonesia',
    avatar: avatarImpactPaksi
  },
  {
    name: 'M. Arkhan Doohan',
    position: 'Data Analyst',
    company: 'United Tractors',
    avatar: avatarImpactArkhan
  },
  {
    name: 'Dave Emmanuel',
    position: 'Business Development Analyst',
    company: 'Shopee',
    avatar: avatarImpactDave
  },
  {
    name: 'Latifah NH Putri',
    position: 'HR Generalist',
    company: 'TIX ID',
    avatar: avatarImpactLatifah
  },
  {
    name: 'Evira Aurellia',
    position: 'Staff Finance',
    company: 'Basic Pratama Production',
    avatar: avatarImpactEvira
  },
  {
    name: 'Dzul Fiqram Nur',
    position: 'Content Specialist',
    company: 'Amar Bank',
    avatar: avatarImpactDzul
  },
  {
    name: 'M. Rajib Abdul G.',
    position: 'Business Development Executive',
    company: 'PT. Dachin Etech Global',
    avatar: avatarImpactRajib
  },
  {
    name: 'Yusril Azmi',
    position: 'Social Media Specialist',
    company: 'Digitaluna.id',
    avatar: avatarImpactYusril
  },
  {
    name: 'Hamdiki Ainur',
    position: 'Associate Acquisition',
    company: 'ADA',
    avatar: avatarImpactHamdiki
  },
  {
    name: 'Brian Marco',
    position: 'Communications Designer',
    company: 'C+C&Co',
    avatar: avatarImpactBrian
  },
  {
    name: 'Dedy Setiyawan',
    position: 'Digital Marketing',
    company: 'ASTRNT Technologies',
    avatar: avatarImpactDedy
  },
  {
    name: 'Arya Dharma',
    position: 'Digital Marketing Specialist',
    company: 'Imuni.id',
    avatar: avatarImpactArya
  },
  {
    name: 'M. Fauzan Labib',
    position: 'Performance Marketing',
    company: 'Home Appliances Company',
    avatar: avatarImpactFauzan
  },
  {
    name: 'Muhammad Rizqullah',
    position: 'Digital Marketing Intern',
    company: 'Erajaya Swasembada',
    avatar: avatarImpactRizqullah
  },
  {
    name: 'Muhammad Rizki M.',
    position: 'Technical Intern',
    company: 'PT. Nestle Indonesia',
    avatar: avatarImpactRizki
  }
])

// ----------------------------------------------------------------------

export const BOOTCAMP_TESTIMONY = Object.freeze([
  {
    alt: 'Paksi Cahyo Baskoro - Copywriter at DBS Bank Indonesia',
    image: posterMemberPaksi,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-paksi-cahyo-dari-jurusan-hukum-sampai-jadi-copywriter-usai-bootcamp-digital-marketing-myskill/'
  },
  {
    alt: 'Amanda Rahmah M. F. - Pengolah Data at Kementerian Sosial RI',
    image: posterMemberAmanda,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-amanda-rahmah-jadi-data-processing-di-kemensos-setelah-bootcamp-data-analysis-myskill/'
  },
  {
    alt: 'David Prayogo - Data Entry & Collection Specialist at Grab Indonesia',
    image: posterMemberDavid,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-david-prayogo-diterima-bekerja-di-grab-usai-ikut-bootcamp-microsoft-excel-myskill/'
  },
  {
    alt: 'Andi Asyha Zalika A. P. - HR Talent Acquisition at Pinus Merah Abadi',
    image: posterMemberAndi,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-andi-aysha-diterima-kerja-jadi-hrd-usai-belajar-di-bootcamp-hr-bsnp-myskillreview-myskill-andi-aysha-diterima-kerja-jadi-hrd-usai-belajar-di-bootcamp-hr-bsnp-myskillreview-myskill/'
  },
  {
    alt: 'Ahmad Jordan - UIUX Designer at Noak Company',
    image: posterMemberAhmad,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-ahmad-jordan-diterima-sebagai-ui-ux-designer-di-perusahaan-singapura-setelah-ikut-bootcamp-ui-ux-myskill/'
  },
  {
    alt: 'Rivia Rizkika - Marketing Partnership Supervisor at Heykama',
    image: posterMemberRivia,
    storyURL: 'https://www.instagram.com/p/C66DtAjphJu/'
  },
  {
    alt: 'M. Zudi Hardiansyah - Associate Product Manager at Solecode',
    image: posterMemberZudi,
    storyURL: 'https://www.instagram.com/p/C8CJdippLq-/'
  },
  {
    alt: 'Ismayati Purnama - HR Operations at Jago Coffee',
    image: posterMemberIsmayati,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-ismayati-purnama-diterima-jadi-hr-di-jago-coffee-usai-belajar-di-bootcamp-hr-bnsp-myskill/'
  },
  {
    alt: 'M. Arkhan Doohan - CSR Data Management System at United Tractors',
    image: posterMemberArkhan,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-arkhan-doohan-jadi-data-analyst-di-united-tractor-usai-bootcamp-microsoft-excel-myskill/'
  },
  {
    alt: 'Khai Rani - UIUX Designer at Andara Rejo Makmur',
    image: posterMemberKhai,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-khai-rani-menjadi-ui-ux-desainer-setelah-ikut-bootcamp-ui-ux-design-myskill/'
  },
  {
    alt: 'Bery Arlanto - Customer Loyalty Team at Tada',
    image: posterMemberBerry,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-berry-arlanto-diterima-kerja-usai-bootcamp-microsoft-excel-myskill/'
  },
  {
    alt: 'Asyafa Umayah - Copywriter at BisnisHack',
    image: posterMemberAsyafa,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-asyafa-umayah-diterima-kerja-jadi-copywriter-usai-belajar-di-bootcamp-myskill/'
  },
  {
    alt: 'Hariyono Ngaslam - Supervisor Data Analyst & Scientist at JNE',
    image: posterMemberHariyono,
    storyURL: 'https://www.instagram.com/p/C4p6MQipdrt/'
  },
  {
    alt: 'Syfa Rizky Putri - Drafter Fiber Optik Intern at Telkom Akses',
    image: posterMemberSyfa,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-syfa-rizky-lolos-seleksi-di-telkom-usai-ikut-bootcamp-microsoft-excel-myskill/'
  },
  {
    alt: 'Rifqi Hanif Sulthoni - HRD at F&B Industry',
    image: posterMemberRifqi,
    storyURL: 'https://www.instagram.com/myskill.id/p/C-AUd6PpX1z/'
  },
  {
    alt: 'Alinda Putri - Social Media Specialist at Manna Indo Lakta',
    image: posterMemberAlinda,
    storyURL: 'https://www.instagram.com/myskill.id/p/C8mNApCJbYl/'
  },
  {
    alt: 'Faizal Alwan - UIUX Designer at PT. Esensi Solusi Buana',
    image: posterMemberFaizal,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-faizal-alwan-diterima-kerja-jadi-ui-ux-designer-usai-ikut-bootcamp-ui-ux-design-myskill/'
  },
  {
    alt: 'Evira Aurellia Z. - Staff Finance at Basic Pratama Production',
    image: posterMemberEvira,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-evira-aurellia-bekerja-sebagai-finance-usai-bootcamp-microsoft-excel-myskill/'
  },
  {
    alt: 'M. Zainurrahman - Data Analyst at FMCG Company',
    image: posterMemberZainurrahman,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-zainurrahman-menjadi-data-analyst-di-fmcg-company-setelah-bootcamp-data-analysis-myskill/'
  },
  {
    alt: 'Syahrina Utami F - Marketing Staff at MCMMediaNetworks',
    image: posterMemberSyahrina,
    storyURL: 'https://www.instagram.com/p/C6oBxGTp_nm/'
  },
  {
    alt: 'Nizam Haqqizar - UIUX Designer at Global Artisan Teknologi (Aiconec)',
    image: posterMemberNizam,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-nizam-haqqizar-switch-career-jadi-ui-ux-designer-usai-bootcamp-ui-ux-design-myskill/'
  },
  {
    alt: 'Soraya Ratna Pratiwi - Social Media Specialist at Ibu-ibu Kota Hujan',
    image: posterMemberSoraya,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-soraya-ratna-dari-ibu-rumah-tangga-ke-social-media-specialist-usai-bootcamp-digital-marketing-myskill/'
  },
  {
    alt: 'Aditya Pratama - HR Staff at Lautan Berlian Utama Motors',
    image: posterMemberAditya,
    storyURL: 'https://www.instagram.com/p/C7wKsA8pg7O/'
  },
  {
    alt: 'Fathia Aqilah I. N. - Data Operator at HPAI Halal Network International',
    image: posterMemberFathia,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-fathia-aqilah-kerja-di-halal-network-international-usai-bootcamp-microsoft-excel-di-myskill/'
  },
  {
    alt: 'Stevano Reynerich - UIUX Designer at Permata Indonesia',
    image: psoterMemberStevano,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-stevano-reynerich-kerja-jadi-ui-ux-designer-usai-ikut-bootcamp-ui-ux-design-myskill/'
  },
  {
    alt: 'Kaamil Nailal Muna - Business Intelligence Intern at XL Axiata',
    image: posterMemberKaamil,
    storyURL: 'https://www.instagram.com/myskill.id/p/C6WAHyapCk-/'
  }
])

export const MENTORING_CAREER_TESTIMONY = Object.freeze([
  {
    alt: 'Dedy Setiawan - Digital Marketing Astronaut',
    image: posterMemberDedy,
    storyURL: 'https://www.instagram.com/p/Cut2Xc3J9E2/'
  },
  {
    alt: 'M. Fauzan Labib - Performance Marketing',
    image: posterMemberFauzan,
    storyURL: 'https://www.instagram.com/p/CwxKhJNJdWU/'
  },
  {
    alt: 'Hafidha Biantoro - Project Admin',
    image: posterMemberHafidha,
    storyURL: 'https://www.instagram.com/p/Ct87Ot9pixg/'
  },
  {
    alt: 'Hana Annisa - Sales Management Trainee Youvit',
    image: posterMemberHana,
    storyURL: 'https://www.instagram.com/p/CsbYh09pItR/'
  },
  {
    alt: 'M. Rajib Abdul Ghani - Business Development Executive DAICHIN',
    image: posterMemberRajib,
    storyURL: 'https://www.instagram.com/p/CsQ8KXtJTar/'
  },
  {
    alt: 'Yusril Azmi - Digital Marketing Specialist Digitaluna.id',
    image: posterMemberYusril,
    storyURL: 'https://www.instagram.com/p/CvXJ5oOJ7qe/'
  }
])

export const ELEARNING_TESIMONY = Object.freeze([
  {
    alt: 'Taufik Rafi Margacahyo - Quality Assurance Tester at Bank Negara Indonesia (BNI)',
    image: posterMemberTaufik,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-taufik-rafli-berhasil-keterima-kerja-di-bni-setelah-belajar-elearning-myskill/'
  },
  {
    alt: 'Latifah NH Putri - HR Generalist at TIX ID',
    image: posterMemberLatifah,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-latifah-putri-diterima-kerja-sebagai-hrd-setelah-belajar-di-e-learning-myskill/'
  },
  {
    alt: 'Muhammad Rizqullah - Performance Marketing Intern at Jakmall.com',
    image: posterMemberRizqullah,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-m-rizqullah-difabel-yang-diterima-di-12-tempat-magang-berkat-belajar-di-elearning-myskill/'
  },
  {
    alt: 'Siti Mustika Ayu - Admin Data Analyst at Gloria Origita Cosmetics',
    image: posterMemberSiti,
    storyURL: 'https://www.instagram.com/p/C8Wvzlep1qz/'
  },
  {
    alt: 'Bram Arya Setiawan - Member of Magenta Batch 3 at Perkebunan Nusantara',
    image: posterMemberBram,
    storyURL: 'https://www.instagram.com/p/C7OpkEJpb1u/'
  },
  {
    alt: 'Rindi Agustina - Content Writer at Halal Network International',
    image: posterMemberRindi,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-rindi-agustiana-diterima-jadi-fulltime-copywriter-usai-belajar-di-e-learning-myskill/'
  },
  {
    alt: 'Agustinus P. - Web Administrator Intern at Atma Jaya Catholic University',
    image: posterMemberAgustinus,
    storyURL: 'https://www.instagram.com/p/Cw7GGu2pLbu/'
  },
  {
    alt: 'Rizki Amalina Putri - Transport Admin at Precast Industry',
    image: posterMemberRizkiAP,
    storyURL: 'https://www.instagram.com/myskill.id/p/C9uS2xbpr_d/'
  },
  {
    alt: 'Luthfiani Elsa - Human Capital Officer at Kurasi Media Indonesia',
    image: posterMemberLuthfiani,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-luthfiani-elsa-jadi-human-capital-officer-usai-belajar-di-elearning-myskill/'
  },
  {
    alt: 'Sindy Aulia Faradila - Data Analyst Intern at StickEarn',
    image: posterMemberSindy,
    storyURL: 'https://www.instagram.com/p/C1WtiCcp5Mr/'
  }
])

export const REVIEW_CV_TESTIMONY = Object.freeze([
  {
    alt: 'Ori Meivani - Digital Transformation Office at Kemenkes RI',
    image: posterMemberOri,
    storyURL: 'https://www.instagram.com/p/Ctq749YpPfP/'
  },
  {
    alt: 'Irgi Maulana - Sales Taking Order at FMCG Company',
    image: posterMemberIrgi,
    storyURL: 'https://www.instagram.com/p/C4X1MXxJa6-/'
  },
  {
    alt: 'Novela Dela - Graphic Designer Bank Kalsel',
    image: posterMemberNovela,
    storyURL: 'https://www.instagram.com/p/CwPsiVRpqsc/'
  },
  {
    alt: 'M. Rizki Maulana - IT&D Power Platform Developer at Reckitt',
    image: posterMemberRizkiMaulana,
    storyURL: 'https://www.instagram.com/p/CtRLiNYpu7T/'
  },
  {
    alt: 'Anggito Setya Ichsan - PPA Leader Development Program at Putra Perkasa Abadi',
    image: posterMemberAnggito,
    storyURL: 'https://www.instagram.com/p/CwckjiVpnLi/'
  },
  {
    alt: 'Hadi Kurniadi - B2C Operation Staff at Lucky Mom Indonesia',
    image: posterMemberHadi,
    storyURL: 'https://www.instagram.com/p/CuO80MPpOw6/'
  },
  {
    alt: 'Kelvin Allen Juliano - Relationship Manager Funding & Transaction at Bank Rakyat Indonesia',
    image: posterMemberKelvin,
    storyURL: 'https://www.instagram.com/p/C29tlDjJKM_/'
  }
])

// ----------------------------------------------------------------------

export const HOMEPAGE_TESTIMONY = Object.freeze([
  {
    alt: 'Soraya Ratna Pratiwi - Social Media Specialist at Ibu-ibu Kota Hujan',
    image: posterMemberSoraya,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-soraya-ratna-dari-ibu-rumah-tangga-ke-social-media-specialist-usai-bootcamp-digital-marketing-myskill/'
  },
  {
    alt: 'Taufik Rafi Margacahyo - Quality Assurance Tester at Bank Negara Indonesia (BNI)',
    image: posterMemberTaufik,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-taufik-rafli-berhasil-keterima-kerja-di-bni-setelah-belajar-elearning-myskill/'
  },
  {
    alt: 'Ori Meivani - Digital Transformation Office at Kemenkes RI',
    image: posterMemberOri,
    storyURL: 'https://www.instagram.com/p/Ctq749YpPfP/'
  },
  {
    alt: 'David Prayogo - Data Entry & Collection Specialist at Grab Indonesia',
    image: posterMemberDavid,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-david-prayogo-diterima-bekerja-di-grab-usai-ikut-bootcamp-microsoft-excel-myskill/'
  },
  {
    alt: 'Siti Mustika Ayu - Admin Data Analyst at Gloria Origita Cosmetics',
    image: posterMemberSiti,
    storyURL: 'https://www.instagram.com/p/C8Wvzlep1qz/'
  },
  {
    alt: 'Anggito Setya Ichsan - PPA Leader Development Program at Putra Perkasa Abadi',
    image: posterMemberAnggito,
    storyURL: 'https://www.instagram.com/p/CwckjiVpnLi/'
  },
  {
    alt: 'Amanda Rahmah M. F. - Pengolah Data at Kementerian Sosial RI',
    image: posterMemberAmanda,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-amanda-rahmah-jadi-data-processing-di-kemensos-setelah-bootcamp-data-analysis-myskill/'
  },
  {
    alt: 'Irgi Maulana - Sales Taking Order at FMCG Company',
    image: posterMemberIrgi,
    storyURL: 'https://www.instagram.com/p/C4X1MXxJa6-/'
  },
  {
    alt: 'Rindi Agustina - Content Writer at Halal Network International',
    image: posterMemberRindi,
    storyURL:
      'https://blog.myskill.id/testimoni-myskill/review-myskill-rindi-agustiana-diterima-jadi-fulltime-copywriter-usai-belajar-di-e-learning-myskill/'
  },
  {
    alt: 'M. Zudi Hardiansyah - Associate Product Manager at Solecode',
    image: posterMemberZudi,
    storyURL: 'https://www.instagram.com/p/C8CJdippLq-/'
  },
  {
    alt: 'Rizki Amalina Putri - Transport Admin at Precast Industry',
    image: posterMemberRizkiAP,
    storyURL: 'https://www.instagram.com/myskill.id/p/C9uS2xbpr_d/'
  },
  {
    alt: 'Rifqi Hanif Sulthoni - HRD at F&B Industry',
    image: posterMemberRifqi,
    storyURL: 'https://www.instagram.com/myskill.id/p/C-AUd6PpX1z/'
  }
])
